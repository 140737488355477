import * as React from "react";
import { CloudIcon, ServerIcon, WifiIcon } from "@heroicons/react/solid";

import PageTitle from "../../components/elements/PageTitle";
import Header from "../../components/sections/Header";
import ModelPageSection from "../../components/sections/ModelPageSection";
import AttachmentsSection from "../../components/sections/AttachmentsSection";
import AttachmentItem from "../../components/elements/AttachmentItem";
import HighlightsSection from "../../components/sections/HighlightsSection";
import HighlightItem from "../../components/elements/HighlightItem";
import DistributorsSection from "../../components/sections/DistributorsSection";
import DistributorItem from "../../components/elements/DistributorItem";
import Footer from "../../components/sections/Footer";

import certificateFile from "../../images/C2Y-CU-001-01-38 ÄB16 Certifikat_typeA.jpg";
import techSpecFile from "../../images/tech_spec_posplus_II.png";
import LinkSection from "../../components/sections/LinkSection";

const ModelAEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"en"} pageTitle={"Model A"} description={""} />
    <Header lang={"en"} />
    <ModelPageSection
      titleSection={"Model A"}
      descriptionSection={""}
      modelTitle={"Model A - For one cash register"}
      modelDescription={`PosPlus Model A is intended to be connected to a cash register via USB. Can be operated with serial connection for cash registers 5-36 volts. Can also be operated with an external power source, if the serial connection lacks a power supply.`}
      modelImgName={"posplus_blue.jpg"}
      certificatesText={[`For a complete list of certificates click`, `here`]}
      // faqs={[

      // ]}
      licenseSectionTitle="License"
      licenseTitles={["Certificate A UNIT"]}
      licenseContent={[
        `Posplus is a certified product whose label shows which
                          certificate applies to the product. Here is an example
                          of a certificate:`,
      ]}
      certificatesUrl={"https://www.cert2you.se/lista-certifikat-2020"}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      certificateImgNames={["CU-001-01-CERT-06-A.jpg", "C2Y-CU-001-01-38 ÄB16 Certifikat_typeA.jpg"]}
      techSpecContent={[
        `Posplus is a certified product whose label shows which
                          certificate applies to the product. Here is an example
                          of a certificate:`,
      ]}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      techSpecImgNames={["tech_spec_posplus_II.png"]}
    >
      <AttachmentsSection title={"Attachments"}>
        <AttachmentItem
          fileName={"Certificate"}
          file={certificateFile}
          downloadText="Download"
        />
        <AttachmentItem
          fileName={"Technical specification"}
          file={techSpecFile}
          downloadText="Download"
        />
      </AttachmentsSection>

      <LinkSection
        title="Communication protocol"
        /*description="description"*/
        clickText={["To find REST-API communication protocol description, click ", "here"]}
        linkPath="/en/api-doc"
      />

      <LinkSection
        title="Error Codes"
        /*description="description"*/
        clickText={["To find full list of error codes and their definitions, click ", "here"]}
        linkPath="/en/error-codes"
      />

      <HighlightsSection title={"Highlights"}>
        <HighlightItem
          name="Cloud server"
          description={`You can also connect your equipment to the PosPlus control unit through our Cloud Server service.`}
          IconImg={CloudIcon}
        />
        <HighlightItem
          name="Local server"
          description={`You can also use PosPlus (Model C) with your own local server.`}
          IconImg={ServerIcon}
        />
        <HighlightItem
          name="Bluetooth-connection"
          description={`PosPlus can be connected to a mobile cash register via Bluetooth.`}
          IconImg={WifiIcon}
        />
      </HighlightsSection>

      <DistributorsSection title={"Distributors"}>
        <DistributorItem
          title={"Postronic AB"}
          email={"info@postronic.se"}
          phone={"+46 (0) 7 0 594 29 54"}
        />
      </DistributorsSection>
    </ModelPageSection>
    <Footer lang={"en"} />
  </div>
);

export default ModelAEnPage;
